/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Rails from '@rails/ujs'
Rails.start()

import "@hotwired/turbo-rails"

// stimulus
import '../controllers'

import * as ActiveStorage from '@rails/activestorage'

import 'channels'
import { createPopper } from '@popperjs/core'
import { Tooltip, Popover } from 'bootstrap'

import '../stylesheets/main.scss'

import jstz from 'jstimezonedetect'
import Typewriter from 'typewriter-effect/dist/core'

import ClipboardJS from 'clipboard'

window.hide_disabled_elements = function() {
  console.log('hide_disabled_elements')
  var disable_displays = document.querySelectorAll('div.disable-display')
  for (var i = 0; i < disable_displays.length; i++) {
    var disable_display = disable_displays[i]
    var class_to_disable = disable_display.dataset.disableClass
    var objects_to_disable = document.querySelectorAll('.' + class_to_disable)
    for (var j = 0; j < objects_to_disable.length; j++) {
      var object_to_disable = objects_to_disable[j]
      object_to_disable.style.display = 'none'
    }
  }
}

function toggle_trial_code_explainer() {
  if(document.querySelector('#trial-explainer') && document.querySelector('#code-explainer')) {
    if (document.querySelector('input#user_referred_by') &&
        document.querySelector('input#user_referred_by').value == '') {
      document.querySelector('#trial-explainer').style.display = 'block'
      document.querySelector('#code-explainer').style.display = 'none'
    } else {
      document.querySelector('#trial-explainer').style.display = 'none'
      document.querySelector('#code-explainer').style.display = 'block'
    }
  }
}

document.addEventListener('turbo:load', function() {
  console.log('turbo:load')

  // Workaround for audio bug on Safari/MobileSafari
  if(document.getElementById('audio-player-parent') && document.querySelector('.audio-player')) {
    document.getElementById('audio-player-parent').appendChild(document.querySelector('.audio-player').cloneNode(true))
    document.querySelector('.audio-player').remove()
  }

  // Set tooltip animation
  if(document.querySelector('.tooltip-anchor')) {
    ([].slice.call(document.querySelectorAll('.tooltip-anchor'))).forEach((tooltipAnchor) => {
      tooltipAnchor.onclick = function() {
        var divId = this.getAttribute('href')
        document.querySelector('html, body').animate({
          scrollTop: document.querySelector(divId).offsetTop - 57
        }, 100)
      }
    })
  }

  window.hide_disabled_elements()

  if(document.querySelector('[data-bs-toggle="tooltip"]')) {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })
  }

  if(document.querySelector('[data-bs-toggle="popover"]')) {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl)
    })
  }

  if (document.getElementById('user_time_zone')) {
    console.log(document.getElementById('user_time_zone').nodeName)
    if (document.getElementById('user_time_zone').nodeName == 'INPUT') {
      document.getElementById('user_time_zone').value = jstz.determine().name()
      console.log('jstz')
    }
  }

  if(document.querySelector('.clipboard-button')) {
    var clipboard = new ClipboardJS('.clipboard-button')
    clipboard.on('success', function(e) {
      e.clearSelection()
    });
    clipboard.on('error', function(e) {
      console.error('Action:', e.action)
      console.error('Trigger:', e.trigger)
    });
  }

  if (document.querySelector('input#user_referred_by')) {
    toggle_trial_code_explainer()
    document.querySelector('input#user_referred_by').onchange = toggle_trial_code_explainer
    document.querySelector('input#user_referred_by').oninput = toggle_trial_code_explainer
  }

  if (document.getElementById('save_link_from')) {
    new Typewriter('#save_link_from', {
      strings: ['YouTube','Spotify','NPR','the BBC','a podcast website','Apple Podcasts','Patreon','Twitter','CNN','anywhere','Reddit','Mastodon','the Internet Archive','PeerTube','SoundCloud','PBS'],
      loop: true,
      autoStart: true
    })
    new Typewriter('#listen_later_in', {
      strings: ['Apple Podcasts','your favorite podcast app','Pocket Casts','Castro','Downcast','Overcast','Castro'],
      loop: true,
      autoStart: true
    })
  }
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')
